:root {
  --partyfy-color: #00DE83;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ProfilePictureButton, .ProfilePictureButton:hover, .ProfilePictureButton:focus {
  background-color: var(--partyfy-color)!important;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

.menu {
  transition: transform 0.3s ease-in-out;
}

.slide-in {
  transform: translateX(0);
}

.slide-out {
  transform: translateX(100%);
}

.successBox {
  display: none;
}

.successBox.active {
  display: block;
}

.successBox.active.fadeIn {
  animation: fadeIn 1s forwards;
}

.successBox.active.fadeOut {
  animation: fadeOut 1s forwards;
}

.box-hidden {
  transform: translateY(100%);
  transition: transform .5s ease-out;
}

.box-visible {
  transform: translateY(0);
  transition: transform .5s ease-out;
}
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}